import { Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import Carousel from "react-elastic-carousel";

export const BtnArrow = styled(Button)`
  /* margin: -1.2rem; */
  position: ${(props) => (props.theme.isMobilePage ? "absolute" : "static")};
  border-color: none !important;
  background-color: none !important;
  background: none !important;
  border: none;
  height: 100%;
  box-shadow: 0 0 0 0.25rem transparent !important;
  /* margin: auto; */

  z-index: 1;
  ${(props) => (props.theme.isRight ? `right: -.5rem` : "left: -.5rem")};
  visibility: ${(props) => (props.theme.isEdge ? "hidden" : "visible")};
  @media only screen and (max-width: 992px) {
    margin-top: -5rem;
  }
`;

export const Arrow = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  content: url(/images/general/rightarrowcarousel.svg);

  transform: ${(props) => (props.theme.isRight ? `` : "rotate(180deg)")};
  box-shadow: 0px 0px 5px 2px rgba(69, 77, 97, 0.45);
  border-radius: 50%;
  @media only screen and (max-width: 992px) {
    margin-left: ${(props) => (props.theme.isRight ? `0rem` : "1.5rem")};
  }
`;

export const RowCarousel = styled(Row)``;

export const SkeletonCol = styled(Col)`
  margin-left: 1rem;
`;

export const CustomCarousel = styled(Carousel)`
  position: ${(props) => (props.theme.isMobilePage ? "relative" : "static")};
`;
